<template>
  <div>
    <apexchart type="pie" width="100%" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
export default {
  name: 'ApexChart',
  props: {
    chart: Array,
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          type: 'pie',
        },
        labels: [],
        legend: {
          position: 'bottom',
          formatter(seriesName, opts) {
            return [seriesName, ' - ', opts.w.globals.series[opts.seriesIndex]];
          },
        },
      },
    };
  },
  mounted() {
    this.chart.forEach(value => {
      this.chartOptions.labels.push(value.answer);
      this.series.push(value.responseCount);
    });
  },
};
</script>
