<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <PageHeader class="text-lg font-medium mr-auto" :title="title" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <Button
          type="button"
          :to="{ name: 'Banners' }"
          class="box text-gray-700 dark:text-gray-300 flex"
        >
          {{ $t('text.back') }}
        </Button>
      </div>
    </div>
    <div class="intro-y mt-5">
      <div class="intro-y box">
        <div class="py-4 px-5 relative">
          <div class="grid grid-cols-12 gap-6">
            <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-6 space-y-4">
              <div>
                <label class="block font-bold mb-1">
                  {{ $t('text.title') }}
                </label>
                <p>{{ form.title }}</p>
              </div>

              <div>
                <label class="block font-bold mb-1">
                  {{ $t('text.description') }}
                </label>
                <p>{{ form.description }}</p>
              </div>

              <div>
                <label class="block font-bold mb-1">
                  {{ $t('text.status') }}
                </label>
                <p>{{ form.status }}</p>
              </div>
            </div>
            <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-6">
              <label class="block font-bold mb-1">
                {{ $t('text.image') }}
              </label>
              <div v-if="form.gallery">
                <img :src="form.gallery.path" :alt="form.title" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { BANNER_SHOW } from '@/store/actions/banner';
import Button from '@/components/ui/button/Button';
import PageHeader from '@/components/ui/PageHeader';

export default {
  mixins: [validationMixin],

  data() {
    return {
      form: {
        title: '',
        description: '',
        status: 'ACTIVE',
        gallery: null,
      },
    };
  },

  computed: {
    ...mapGetters(['bannerStatus', 'banner', 'getFileUpload']),
    title() {
      return this.$t(`nav.bannerShow`);
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      if (this.$route.params.id) {
        await this.$store.dispatch(BANNER_SHOW, { id: this.$route.params.id });
        if (this.banner) {
          const { title, status, description, gallery } = this.banner;
          this.form = {
            title,
            description,
            status,
            gallery,
          };
        }
      }
    },
  },

  components: {
    Button,
    PageHeader,
  },
};
</script>
