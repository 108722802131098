<template>
  <TableRegular
    :loading="loading"
    :downloading="downloading"
    :rows="rows"
    :columns="columns"
    :sort="sort"
    :page="page"
    :per-page="perPage"
    :page-size="pageSize"
    :total-page="totalPage"
    :line-numbers="lineNumbers"
    :downloadable="false"
    @onFilter="$emit('onFilter', $event)"
    @onPageChange="$emit('onPageChange', $event)"
    @onPerPageChange="$emit('onPerPageChange', $event)"
    @onSortChange="$emit('onSortChange', $event)"
    @onDownload="$emit('onDownload')"
    @onChangeCustomFilter="$emit('onChangeCustomFilter', $event)"
  >
    <template slot="table-row" slot-scope="props">
      <p v-if="props.column.field === 'description'" v-tooltip.top-center="props.row.description">
        {{ props.row.description | textSubstr(30, '...') }}
      </p>
      <div v-else-if="props.column.field === 'status'" class="whitespace-nowrap">
        <div
          v-if="props.row.status"
          :class="['status status-announcement', `is-${props.row.status.toLowerCase()}`]"
        >
          {{ $t(`statusAnnouncement.${props.row.status.toLowerCase()}`) }}
        </div>
      </div>
      <p v-else-if="props.column.field === 'action'" class="flex justify-center items-center">
        <router-link
          :to="{ name: 'BannerUpdate', params: { id: props.row.id } }"
          class="flex items-center mr-3"
        >
          <CheckSquareIcon class="w-4 h-4 mr-1" />
          {{ $t('table.edit') }}
        </router-link>
        <router-link
          :to="{ name: 'BannerDetails', params: { id: props.row.id } }"
          class="flex items-center mr-3"
        >
          <EyeIcon class="w-4 h-4 mr-1" />
          {{ $t('table.read') }}
        </router-link>
        <a
          href="javascript:"
          @click="$emit('delete', props.row.id)"
          class="flex items-center text-theme-6"
        >
          <Trash2Icon class="w-4 h-4 mr-1" />
          {{ $t('table.delete') }}
        </a>
      </p>
    </template>
  </TableRegular>
</template>

<script>
import TableRegular from '@/components/ui/table/TableRegular';
import tableMixin from '@/mixins/tableMixin';

export default {
  mixins: [tableMixin],

  components: {
    TableRegular,
  },
};
</script>
