<template>
  <div :id="id" class="modal ">
    <div class="modal__content overflow-hidden">
      <div class="flex items-center px-5 py-5 sm:py-3 border-b border-gray-200 dark:border-dark-5">
        <h2 class="font-medium text-base mr-auto">{{ title }}</h2>
      </div>
      <div id="printSection">
        <slot></slot>
      </div>
      <div
        class="px-5 py-3 flex items-center justify-between border-t border-gray-200 dark:border-dark-5"
      >
        <Button
          type="button"
          @click="onCancel"
          class="w-20 border text-gray-700 dark:border-dark-5 dark:text-gray-300 mr-1"
        >
          {{ $t('text.cancel') }}
        </Button>
        <Button
          type="button"
          @click="onSubmit"
          :loading="loading"
          class="w-32 bg-theme-1 text-white"
        >
          {{ $t('text.print') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/button/Button';

export default {
  name: 'ModalPrint',

  components: { Button },

  props: {
    id: {
      type: String,
      default: 'print-modal',
    },
    title: String,
    loading: Boolean,
  },

  data() {
    return {
      output: null,
    };
  },

  methods: {
    onCancel() {
      cash(`#${this.id}`).modal('hide');
      this.$emit('onClickCancel');
    },

    onSubmit() {
      this.$htmlToPaper('printSection');
    },
  },
};
</script>
