<template>
  <TableRegular
    :loading="loading"
    :downloading="downloading"
    :rows="rows"
    :columns="columns"
    :custom-filters="customFilters"
    :sort="sort"
    :page="page"
    :per-page="perPage"
    :page-size="pageSize"
    :total-page="totalPage"
    :line-numbers="lineNumbers"
    :downloadable="downloadable && checkPermission('service.services.download')"
    @onFilter="$emit('onFilter', $event)"
    @onPageChange="$emit('onPageChange', $event)"
    @onPerPageChange="$emit('onPerPageChange', $event)"
    @onSortChange="$emit('onSortChange', $event)"
    @onDownload="$emit('onDownload')"
    @onChangeCustomFilter="$emit('onChangeCustomFilter', $event)"
  >
    <template slot="table-row" slot-scope="props">
      <p v-if="props.column.field === 'assignedUser'">
        <PeopleFullname v-if="props.row.assignedUser" :item="props.row.assignedUser" />
      </p>
      <p v-else-if="props.column.field === 'title'" class="w-44">
        {{ props.row.title }}
      </p>
      <p v-else-if="props.column.field === 'building'">
        {{ props.row.building ? props.row.building.name : '-' }}
      </p>
      <div v-else-if="props.column.field === 'apartments'" class="w w-36">
        <template v-if="props.row.apartments && props.row.apartments.length > 0">
          <template v-for="(item, index) in props.row.apartments">
            <span v-if="index < 10" :key="index" class="mr-2">
              {{ item.number }}<span v-if="index < 9">,</span>
            </span>
          </template>
          <a
            v-if="props.row.apartments.length > 10"
            @click="openModal(props.row.id)"
            class="underline cursor-pointer"
            >{{ $t('text.seeMore') }}</a
          >
          <ModalEdit
            :id="`modal-see-more-apartments-${props.row.id}`"
            :title="$t('text.apartments')"
            :save-button="false"
          >
            <div class="p-6">
              <template v-for="(item, index) in props.row.apartments">
                <span :key="index" class="mr-2">
                  {{ item.number }}<span v-if="index < props.row.apartments.length - 1">,</span>
                </span>
              </template>
            </div>
          </ModalEdit>
        </template>
      </div>
      <p v-else-if="props.column.field === 'createdAt'" class="font-medium whitespace-nowrap">
        {{ props.row.createdAt | momentDate('YYYY-MM-DD HH:mm') }}
      </p>
      <template v-else-if="props.column.field === 'status'">
        <div v-if="props.row.status" :class="['status', `is-${props.row.status.toLowerCase()}`]">
          {{ $t(`status.${props.row.status.toLowerCase()}`) }}
        </div>
      </template>
      <template v-else-if="props.column.field === 'comments'">
        <span v-if="props.row.comments">
          {{ props.row.comments.length }}
        </span>
      </template>
      <p v-else-if="props.column.field === 'action'" class="flex justify-center items-center">
        <router-link
          :to="{ name: 'ForumComments', params: { id: props.row.id } }"
          class="flex items-center mr-3"
        >
          <MessageSquareIcon class="w-4 h-4 mr-1" />
          {{ $t('table.comments') }}
        </router-link>
        <router-link
          :to="{ name: 'ForumUpdate', params: { id: props.row.id } }"
          class="flex items-center mr-3"
        >
          <CheckSquareIcon class="w-4 h-4 mr-1" />
          {{ $t('table.edit') }}
        </router-link>
        <a
          href="javascript:"
          @click="$emit('delete', props.row.id)"
          class="flex items-center text-theme-6"
        >
          <Trash2Icon class="w-4 h-4 mr-1" />
          {{ $t('table.delete') }}
        </a>
      </p>
    </template>
  </TableRegular>
</template>

<script>
import TableRegular from '@/components/ui/table/TableRegular';
import tableMixin from '@/mixins/tableMixin';
import PeopleFullname from '@/components/people/PeopleFullname';
import ModalEdit from '@/components/ui/ModalEdit';

export default {
  mixins: [tableMixin],

  methods: {
    openModal(index) {
      cash(`#modal-see-more-apartments-${index}`).modal('show');
    },
  },

  components: {
    TableRegular,
    PeopleFullname,
    ModalEdit,
  },
};
</script>

<style>
.tooltip {
  display: block !important;
  z-index: 10000;
  width: 250px;
}
.tooltip .tooltip-inner {
  background: #1d3faa;
  color: white;
  border-radius: 16px;
  padding: 10px;
}
.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}
.tooltip[x-placement^='top'] {
  margin-bottom: 5px;
}
.tooltip[x-placement^='top'] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
.tooltip[x-placement^='bottom'] {
  margin-top: 5px;
}
.tooltip[x-placement^='bottom'] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
.tooltip[x-placement^='right'] {
  margin-left: 5px;
}
.tooltip[x-placement^='right'] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}
.tooltip[x-placement^='left'] {
  margin-right: 5px;
}
.tooltip[x-placement^='left'] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}
.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}
.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}
</style>
