import Ticket from '@/views/Ticket/index';
import Tickets from '@/views/Ticket/Tickets';
import TicketForm from '@/views/Ticket/TicketForm';
import TicketDetails from '@/views/Ticket/TicketDetails';
import StatusTabs from '@/views/Ticket/StatusTabs';

export default [
  {
    path: '/tickets',
    component: Ticket,
    meta: { group: 'tickets' },
    children: [
      {
        path: '',
        component: StatusTabs,
        children: [
          { path: '', name: 'Tickets', component: Tickets },
          { path: 'sent', name: 'TicketSent', component: Tickets },
          { path: 'seen', name: 'TicketSeen', component: Tickets },
          { path: 'solved', name: 'TicketSolved', component: Tickets },
        ],
      },
      { path: 'create', name: 'TicketCreate', component: TicketForm },
      { path: 'update/:id', name: 'TicketUpdate', component: TicketForm },
      { path: 'details/:id', name: 'TicketDetails', component: TicketDetails },
    ],
  },
];
