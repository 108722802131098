<template>
  <div class="people-full-name">
    <p class="font-medium whitespace-nowrap">{{ item | fullName }}</p>
    <p class="text-gray-600 text-xs whitespace-nowrap">{{ item.phone }}</p>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.people-full-name {
  & + .people-full-name {
    @apply mt-2;
  }
}
</style>
