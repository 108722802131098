import Index from '@/views/Forums/Index';
import Forums from '@/views/Forums/Forums';
import ForumForm from '@/views/Forums/ForumForm';
import ForumComments from '@/views/Forums/ForumComments';

export default [
  {
    path: '/forums',
    component: Index,
    meta: { group: 'forums' },
    children: [
      {
        path: '',
        component: Forums,
        name: 'Forums',
      },
      {
        path: 'create',
        name: 'ForumCreate',
        component: ForumForm,
      },
      {
        path: 'update/:id',
        name: 'ForumUpdate',
        component: ForumForm,
      },
      {
        path: ':id/comments',
        name: 'ForumComments',
        component: ForumComments,
      },
    ],
  },
];
