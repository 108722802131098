import Index from '@/views/Banners/Index';
import Banners from '@/views/Banners/Banners';
import BannerForm from '@/views/Banners/BannerForm';
import BannerDetails from '@/views/Banners/BannerDetails';

export default [
  {
    path: '/banners',
    component: Index,
    meta: { group: 'banner' },
    children: [
      {
        path: '',
        component: Banners,
        name: 'Banners',
      },
      {
        path: 'create',
        name: 'BannerCreate',
        component: BannerForm,
      },
      {
        path: 'update/:id',
        name: 'BannerUpdate',
        component: BannerForm,
      },
      {
        path: 'details/:id',
        name: 'BannerDetails',
        component: BannerDetails,
      },
    ],
  },
];
