<template>
  <div :id="modalId" class="modal">
    <div class="modal__content">
      <div class="p-5 text-center">
        <MailIcon class="w-16 h-16 text-theme-11 mx-auto mt-3" />
        <div class="text-3xl mt-5">{{ $t('deleteConfirmDialog.title') }}</div>
        <div class="text-gray-600 mt-2" v-if="startDate">
          {{ $t('smsConfirmDialog.description_1') }} <b>{{ apartmentNumber }}</b>
          {{ $t('smsConfirmDialog.description_2') }} <b>{{ startDate }}</b>
          {{ $t('smsConfirmDialog.description_3') }}
        </div>
        <div class="text-1xl mt-5">
          <p>
            {{ $t('text.smsBalance') }}: <b>{{ oldSmsCount }}</b>
          </p>
          <p>
            {{ $t('text.totalInvoices') }}: <b>{{ smsCount }}</b>
          </p>
          <p>
            {{ $t('text.nowTotalSendingSMS') }}: <b>{{ totalSmsCount }}</b>
          </p>
        </div>
      </div>
      <div class="px-5 pb-8 text-center">
        <Button
          type="button"
          @click="onCancel"
          class="w-24 border text-gray-700 dark:border-dark-5 dark:text-gray-300 mr-1"
        >
          {{ $t('text.cancel') }}
        </Button>
        <Button
          v-if="oldSmsCount > smsCount"
          type="button"
          :loading="isLoading"
          class="w-30 bg-theme-11 text-white"
          @click="onSubmit"
        >
          {{ $t('text.sendSms') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/button/Button';
import { mapGetters } from 'vuex';
import { RECEIVABLES_SMS_SEND } from '@/store/actions/report';
import moment from 'moment';

export default {
  name: 'SmsDialog',

  components: { Button },

  data() {
    return {
      apartmentNumber: String,
      startDate: String,
    };
  },

  props: {
    modalId: {
      type: String,
      default: 'sms-show-modal',
    },
    filters: Array,
    businessId: Number,
    filterable: String,
    oldSmsCount: Number,
    smsCount: Number,
    totalSmsCount: Number,
  },

  computed: {
    ...mapGetters(['receivablesSendSms']),
    isLoading() {
      return this.receivablesSendSms === 'loading';
    },
  },

  watch: {
    filters: {
      handler(val) {
        if (val[0].value) {
          const index = val[0].options.findIndex(item => item.id === Number(val[0].value));
          this.apartmentNumber = val[0].options[index].name;
        } else {
          this.apartmentNumber = this.$t('smsConfirmDialog.all');
        }
        this.startDate = val[2].value;
      },
      deep: true,
    },
  },

  methods: {
    moment() {
      return moment();
    },

    onCancel() {
      cash(`#${this.modalId}`).modal('hide');
    },
    async onSubmit() {
      try {
        const payload = {
          businessId: this.businessId,
          data: {
            filter: {
              buildingId: this.filters[0].value,
              status: this.filters[1].value,
              start_date: this.filters[3].value,
              number: this.filterable,
            },
          },
        };
        await this.$store.dispatch(RECEIVABLES_SMS_SEND, payload);

        if (this.receivablesSendSms === 'success') {
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
          this.onCancel();
        }
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
    },
  },
};
</script>
