<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-5">
      <PageHeader class="text-lg font-medium mr-auto" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <router-link
          :to="{ name: 'ForumCreate' }"
          class="button text-white bg-theme-1 shadow-md mr-2"
        >
          {{ $t('text.create') }}
        </router-link>
      </div>
    </div>
    <div class="intro-y mt-5 overflow-auto lg:overflow-visible">
      <ForumsTable
        :loading="isLoading"
        :rows="forumList"
        :columns="columns"
        :line-numbers="true"
        :sort="sort"
        :page="page"
        :per-page="perPage"
        :page-size="pageSize"
        :total-page="totalPage"
        :footerItems="footerItems"
        :custom-filters="customFilters"
        @delete="confirmRemoval"
        @show="onShowModal($event)"
        @pay="onShowModal($event, 'pay')"
        @onFilter="onFilter"
        @onPageChange="onPageChange"
        @onPerPageChange="onPerPageChange"
        @onSortChange="onSortChange"
        @onDownload="onDownload"
        @onChangeCustomFilter="onChangeCustomFilter"
      />
    </div>
    <ConfirmDialog
      :modal-id="deleteModalId"
      :is-loading="isDeleting ? true : false"
      @ok="onDelete"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FORUMS_LIST_REQUEST, FORUMS_DELETE } from '@/store/actions/forums';
import dataListMixin from '@/mixins/dataListMixin';
import PageHeader from '@/components/ui/PageHeader';
import ForumsTable from '@/components/forums/ForumsTable';

export default {
  mixins: [dataListMixin],
  components: { PageHeader, ForumsTable },
  data() {
    return {
      storeActionListRequest: FORUMS_LIST_REQUEST,
      storeActionRemove: FORUMS_DELETE,
      columns: [
        { label: this.$t('table.name'), field: 'title', filterable: 'true' },
        { label: this.$t('table.buildingName'), field: 'building' },
        { label: this.$t('table.doorNumber'), field: 'apartments' },
        { label: this.$t('table.respondent'), field: 'assignedUser' },
        { label: this.$t('table.status'), field: 'status' },
        { label: this.$t('table.comments'), field: 'comments' },
        { label: this.$t('table.createdDate'), field: 'createdAt' },
        {
          label: this.$t('table.respondent'),
          field: 'assignedName',
          filterable: 'true',
          disabled: true,
        },
        { label: this.$t('table.action'), field: 'action' },
      ],
      customFilters: [
        {
          key: 'status',
          value: null,
          label: this.$t('text.type'),
          type: 'select',
          options: [
            {
              id: 'ACTIVE',
              name: this.$t('text.active'),
            },
            {
              id: 'INACTIVE',
              name: this.$t('text.inactive'),
            },
            {
              id: 'CLOSED',
              name: this.$t('text.closedStatus'),
            },
          ],
        },
        {
          key: 'startDate',
          value: null,
          label: this.$t('text.selectStartDate'),
          type: 'day',
        },
        {
          key: 'endDate',
          value: null,
          label: this.$t('text.selectEndDate'),
          type: 'day',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['forumList', 'forumsMeta', 'forumsStatus']),
    isLoading() {
      return this.forumsStatus === 'loading';
    },
    footerItems() {
      return this.forumsMeta
        ? {
            totalAmount: this.forumsMeta.totalAmount,
            total: this.forumsMeta.total,
          }
        : {};
    },
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    onChangeCustomFilter(object) {
      const index = this.customFilters.findIndex(item => item.key === object.key);
      if (index > -1) {
        const { value } = object;
        this.customFilters[index].value = value || null;
        this.fetchData();
      }
    },
    setFilterValues(data) {
      this.customFilters.forEach(item => {
        data.filters[`filter[${item.key}]`] = item.value;
      });
    },
  },
};
</script>
