<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <PageHeader class="text-lg font-medium mr-auto" :title="title" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <Button
          type="button"
          :to="{ name: 'Tickets' }"
          class="box text-gray-700 dark:text-gray-300 flex"
        >
          {{ $t('text.back') }}
        </Button>
      </div>
    </div>
    <div class="intro-y mt-5">
      <div class="grid grid-cols-12 gap-6">
        <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-4 xl:col-span-4">
          <div class="grid grid-cols-12 gap-6">
            <div class="intro-y col-span-12 sm:col-span-6 lg:col-span-12 xl:col-span-12">
              <div class="box">
                <div class="p-5 border-b border-gray-200 dark:border-dark-5">
                  <h2 class="font-medium text-base">{{ $t('nav.ownerShow') }}</h2>
                </div>
                <div v-if="owner" class="intro-y py-4 px-5 relative">
                  <PeopleFullname :item="owner" />
                </div>
              </div>
            </div>
            <div class="intro-y col-span-12 sm:col-span-6 lg:col-span-12 xl:col-span-12">
              <div class="box">
                <div class="intro-y p-5 border-b border-gray-200 dark:border-dark-5">
                  <h2 class="font-medium text-base">{{ $t('nav.buildingShow') }}</h2>
                </div>
                <div class="intro-y py-4 px-5 space-y-4">
                  <div>
                    <label class="block font-bold mb-1">
                      {{ $t('text.buildingName') }}
                    </label>
                    <p>{{ buildingName }}</p>
                  </div>

                  <div>
                    <label class="block font-bold mb-1">
                      {{ $t('text.doorNumber') }}
                    </label>
                    <p>{{ doorNumber }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-8 xl:col-span-8">
          <div class="intro-y box">
            <div class="p-5 border-b border-gray-200 dark:border-dark-5">
              <h2 class="font-medium text-base">{{ $t('nav.ticketShow') }}</h2>
            </div>
            <div class="py-4 px-5 relative">
              <div class="grid grid-cols-12 gap-6 mb-4">
                <div
                  class="intro-y col-span-12 sm:col-span-12 lg:col-span-6 xl:col-span-6 space-y-4"
                >
                  <div>
                    <label class="block font-bold mb-1">
                      {{ $t('text.category') }}
                    </label>
                    <p>{{ ticket.ticketCategory ? ticket.ticketCategory.name : '' }}</p>
                  </div>

                  <div>
                    <label class="block font-bold mb-1">
                      {{ $t('text.type') }}
                    </label>
                    <p>{{ ticket.ticketType ? ticket.ticketType.name : '' }}</p>
                  </div>

                  <div>
                    <label class="block font-bold mb-1">
                      {{ $t('text.priority') }}
                    </label>
                    <p>{{ priority }}</p>
                  </div>

                  <div>
                    <label class="block font-bold mb-1">
                      {{ $t('text.channel') }}
                    </label>
                    <p>{{ channel }}</p>
                  </div>

                  <div>
                    <label class="block font-bold mb-1">
                      {{ $t('text.date') }}
                    </label>
                    <p>{{ form.ticketDate }}</p>
                  </div>
                </div>
                <div
                  class="intro-y col-span-12 sm:col-span-12 lg:col-span-6 xl:col-span-6 space-y-4"
                >
                  <div>
                    <label class="block font-bold mb-1">
                      {{ $t('text.title') }}
                    </label>
                    <p>{{ form.title }}</p>
                  </div>

                  <div>
                    <label class="block font-bold mb-1">
                      {{ $t('text.description') }}
                    </label>
                    <p>{{ form.description }}</p>
                  </div>
                </div>
              </div>

              <label class="block font-bold mb-1">
                {{ $t('text.image') }}
              </label>
              <div v-for="(photo, photoIndex) in form.files" :key="`photo-${photoIndex}`">
                <img :src="photo.path" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { APARTMENT_LIST_REQUEST } from '@/store/actions/apartment';
import { BUILDING_LIST_REQUEST } from '@/store/actions/building';
import {
  TICKET_REQUEST,
  TICKET_CATEGORIES_REQUEST,
  TICKET_TYPES_REQUEST,
} from '@/store/actions/ticket';
import { validationMixin } from 'vuelidate';
import Button from '@/components/ui/button/Button';
import PageHeader from '@/components/ui/PageHeader';
import PeopleFullname from '@/components/people/PeopleFullname';
import { PRIORITY_LEVELS, CHANNELS } from '@/utils/constants';
import moment from 'moment';

export default {
  mixins: [validationMixin],

  components: {
    Button,
    PageHeader,
    PeopleFullname,
  },

  props: {
    business: Object,
  },

  data() {
    return {
      form: {
        ticketCategoryId: null,
        ticketTypeId: null,
        priority: null,
        channel: null,
        ticketDate: moment().format('YYYY-MM-DD'),
        title: '',
        description: '',
        files: [],
      },
      owner: null,
      buildingId: null,
      apartmentId: null,
      priorityList: PRIORITY_LEVELS,
      channelList: CHANNELS,
      defaultTableData: {
        filters: {},
        sort: {
          field: 'id',
          type: 'desc',
        },
        page: 1,
        perPage: 100,
      },
      isOwnerOfThisApartment: false,
    };
  },

  computed: {
    ...mapGetters([
      'ticketStatus',
      'ticket',
      'ticketCategories',
      'ticketTypes',
      'apartmentList',
      'apartmentListStatus',
      'buildingList',
      'buildingStatus',
      'getFileUpload',
    ]),
    title() {
      return this.$t(`nav.ticketShow`);
    },
    buildingName() {
      const building = this.buildingList.find(item => item.id === this.buildingId);
      return building ? building.name : '';
    },
    doorNumber() {
      const apartment = this.apartmentList.find(item => item.id === this.apartmentId);
      return apartment ? apartment.number : '';
    },
    priority() {
      const priority = this.priorityList.find(item => item.id === parseInt(this.form.priority, 10));
      return priority ? priority.name : '';
    },
    channel() {
      const channel = this.channelList.find(item => item.id === this.form.channel);
      return channel ? channel.name : '';
    },
  },

  async created() {
    await this.fetchData();
  },

  methods: {
    async fetchData() {
      if (this.$route.params.id) {
        const payload = { businessId: this.business.id, id: this.$route.params.id };
        await this.$store.dispatch(TICKET_REQUEST, payload);
        if (this.ticket) {
          const {
            ticketCategoryId,
            ticketTypeId,
            priority,
            channel,
            ticketDate,
            title,
            description,
            owner,
            apartment,
            photos,
          } = this.ticket;

          this.form = {
            ticketCategoryId,
            ticketTypeId,
            priority,
            channel,
            ticketDate,
            title,
            description,
            files: photos.map(photo => {
              return photo.gallery;
            }),
          };

          this.owner = owner;
          this.buildingId = apartment ? apartment.buildingId : null;
          await this.getApartmentList();
          this.apartmentId = apartment ? apartment.id : null;
        }
      }

      await this.getBuildingList();
      await this.getTicketCategoriesAndTypes();
    },

    getTicketCategoriesAndTypes() {
      this.$store.dispatch(TICKET_CATEGORIES_REQUEST);
      this.$store.dispatch(TICKET_TYPES_REQUEST);
    },

    async getBuildingList() {
      const payload = {
        businessId: this.business.id,
        data: this.defaultTableData,
      };

      await this.$store.dispatch(BUILDING_LIST_REQUEST, payload);
    },

    async getApartmentList() {
      const data = this.defaultTableData;
      data.filters['filter[buildingId]'] = this.buildingId;
      data.filters['filter[isParking]'] = 0;

      const payload = {
        businessId: this.business.id,
        data,
      };

      await this.$store.dispatch(APARTMENT_LIST_REQUEST, payload);
    },
  },
};
</script>
