<template>
  <ModalPrint :id="modalId" :title="title">
    <div class="p-5 space-y-16">
      <div v-for="(item, index) in poll" :key="`poll-${item.id}-${index}`">
        <div>
          <h4 class="font-bold leading-5 text-lg">{{ item.question }}</h4>
        </div>
        <div style="">
          <ApexChart :chart="item.answers"></ApexChart>
        </div>
      </div>
    </div>
  </ModalPrint>
</template>

<script>
import ModalPrint from '@/components/ui/ModalPrint';
import baseModalMixin from '@/mixins/baseModalMixin';
import ApexChart from '@/components/ui/chart/ApexChart';

export default {
  mixins: [baseModalMixin],

  components: {
    ModalPrint,
    ApexChart,
  },
  props: {
    title: {
      type: String,
    },
    poll: Array,
  },
  data() {
    return {
      options: {
        perPage: 1,
        navigationEnabled: true,
      },
    };
  },
  methods: {
    onCancel() {
      this.hideEditModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel {
  position: relative;
  text-align: left;
}

::v-deep {
  .VueCarousel-navigation-prev {
    margin: 0 !important;
    transform: translate(-50%, -50%);
  }

  .VueCarousel-navigation-next {
    margin: 0 !important;
    transform: translate(50%, -50%);
  }

  .VueCarousel-dot {
    margin-top: 0 !important;
    padding: 6px !important;
  }
}
</style>
