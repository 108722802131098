<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <PageHeader class="text-lg font-medium mr-auto" :title="title" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <Button
          type="button"
          :to="{ name: 'SmsBalances' }"
          class="box text-gray-700 dark:text-gray-300 mr-2 flex"
        >
          {{ $t('text.back') }}
        </Button>
        <Button
          type="button"
          @click="onSubmit"
          :loading="isLoading"
          class="w-24 bg-theme-1 text-white"
        >
          {{ $t('text.save') }}
        </Button>
      </div>
    </div>
    <div class="intro-y mt-5">
      <form @submit.prevent="onSubmit">
        <div class="intro-y box">
          <div class="p-5 border-b border-gray-200 dark:border-dark-5">
            <h2 class="font-medium text-base">{{ $t('nav.bannerShow') }}</h2>
          </div>
          <div class="py-4 px-5 relative">
            <div class="grid grid-cols-12 gap-6">
              <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-6">
                <InputSelect
                  :label="$t('text.selectBusiness')"
                  v-model.number="$v.form.businessId.$model"
                  :options="businessList"
                  value-key="id"
                  :vuelidate="$v.form.businessId"
                  :disabled="isLoadingBusiness"
                  input-class="input-select-sms-balance-business-id"
                />
                <InputSelect
                  :label="$t('text.selectSmsPackage')"
                  v-model.number="$v.form.smsPackageId.$model"
                  :options="smsPackageList"
                  value-key="id"
                  :vuelidate="$v.form.smsPackageId"
                  :disabled="isLoadingSmsPackages"
                  input-class="input-select-sms-balance-package-id"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { BUSINESS_LIST_REQUEST } from '@/store/actions/business';
import { SMS_BALANCE_REQUEST } from '@/store/actions/smsBalance';
import { SMS_PACKAGE_LIST_REQUEST } from '@/store/actions/smsPackage';
import Button from '@/components/ui/button/Button';
import PageHeader from '@/components/ui/PageHeader';
import InputSelect from '@/components/ui/input/InputSelect';

export default {
  mixins: [validationMixin],

  validations() {
    return {
      form: {
        businessId: {
          required,
        },
        smsPackageId: {
          required,
        },
      },
    };
  },

  data() {
    return {
      form: {
        businessId: null,
        smsPackageId: null,
      },
      defaultTableData: {
        filters: {},
        sort: {
          field: 'id',
          type: 'desc',
        },
        page: 1,
        perPage: 1000,
      },
    };
  },

  computed: {
    ...mapGetters([
      'smsBalanceStatus',
      'businessList',
      'businessStatus',
      'smsPackageList',
      'smsPackageStatus',
    ]),
    isLoading() {
      return this.smsBalanceStatus === 'loading';
    },
    isLoadingBusiness() {
      return this.businessStatus === 'loading';
    },
    isLoadingSmsPackages() {
      return this.smsPackageStatus === 'loading';
    },
    title() {
      return this.$t(`nav.smsBalanceCreate`);
    },
  },

  created() {
    this.fetchBusinessList();
    this.fetchSmsPackages();
  },

  methods: {
    async fetchBusinessList() {
      const payload = {
        data: this.defaultTableData,
      };
      await this.$store.dispatch(BUSINESS_LIST_REQUEST, payload);
    },
    async fetchSmsPackages() {
      const payload = {
        data: this.defaultTableData,
      };
      await this.$store.dispatch(SMS_PACKAGE_LIST_REQUEST, payload);
    },
    async onSubmit() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
          return;
        }
      }

      try {
        await this.$store.dispatch(SMS_BALANCE_REQUEST, this.form);
        if (this.smsBalanceStatus === 'success') {
          this.$router.push({ name: 'SmsBalances' });
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
        }
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
    },
  },

  components: {
    Button,
    PageHeader,
    InputSelect,
  },
};
</script>
