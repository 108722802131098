<template>
  <TableRegular
    :loading="loading"
    :rows="rows"
    :columns="columns"
    :sort="sort"
    :page="page"
    :per-page="perPage"
    :page-size="pageSize"
    :total-page="totalPage"
    :line-numbers="lineNumbers"
    :custom-filters="customFilters"
    @onFilter="$emit('onFilter', $event)"
    @onPageChange="$emit('onPageChange', $event)"
    @onPerPageChange="$emit('onPerPageChange', $event)"
    @onSortChange="$emit('onSortChange', $event)"
    @onChangeCustomFilter="$emit('onChangeCustomFilter', $event)"
  >
    <template slot="table-row" slot-scope="props">
      <template v-if="props.column.field === 'ticketType'">
        {{ props.row.ticketType ? props.row.ticketType.name : '' }}
      </template>
      <template v-else-if="props.column.field === 'ticketCategory'">
        {{ props.row.ticketCategory ? props.row.ticketCategory.name : '' }}
      </template>
      <div v-else-if="props.column.field === 'status'">
        <div
          v-if="props.row.status"
          :class="['status status-ticket', `is-${props.row.status.toLowerCase()}`]"
        >
          {{ $t(`statusTicket.${props.row.status.toLowerCase()}`) }}
        </div>
      </div>
      <template v-else-if="props.column.field === 'priority'">
        {{ getPriorityName(props.row.priority) }}
      </template>
      <template v-else-if="props.column.field === 'owner'">
        <PeopleFullname :item="props.row.owner" v-if="props.row.owner" />
        <PeopleFullname :item="props.row.user" v-if="props.row.user" />
      </template>
      <p v-else-if="props.column.field === 'ticketDate'" class="whitespace-nowrap">
        {{ props.row.ticketDate }}
      </p>
      <template v-else-if="props.column.field === 'channel'">
        {{ getChannelName(props.row.channel) }}
      </template>
      <p
        v-else-if="props.column.field === 'description'"
        class="whitespace-nowrap"
        v-tooltip.top-center="props.row.description"
      >
        {{ props.row.description | textSubstr(40, '...') }}
      </p>
      <template v-else-if="props.column.field === 'assignedUser'">
        <PeopleFullname v-if="props.row.assignedUser" :item="props.row.assignedUser" />
      </template>
      <template v-else-if="props.column.field === 'assign'">
        <InputSelect
          v-if="checkPermission('tickets.tickets.edit') && users && users.length > 0"
          :first-item-label="$t('table.assign')"
          first-item-disabled
          :options="users"
          :value="null"
          value-key="id"
          label-key="firstName"
          input-class="input-select-ticket-assign-user sm:w-auto"
          @input="onAssign($event, 'ASSIGNED', props.row.id)"
        />
      </template>
      <template v-else-if="props.column.field === 'statusAction'">
        <InputSelect
          v-if="checkPermission('tickets.tickets.edit')"
          :first-item-label="$t(`statusTicket.${props.row.status.toLowerCase()}`)"
          first-item-disabled
          :options="getOptions(props.row.status)"
          :value="null"
          value-key="id"
          input-class="input-select-ticket-option sm:w-auto"
          @input="onChangeStatus(props.row.assignedUser, $event, props.row.id)"
        />
      </template>
      <p v-else-if="props.column.field === 'action'" class="flex justify-center items-center">
        <router-link
          v-if="checkPermission('tickets.tickets.edit')"
          :to="{ name: 'TicketUpdate', params: { id: props.row.id } }"
          class="flex items-center mr-3"
        >
          <CheckSquareIcon class="w-4 h-4 mr-1" />
          {{ $t('table.edit') }}
        </router-link>
        <router-link
          v-if="checkPermission('tickets.tickets.show')"
          :to="{ name: 'TicketDetails', params: { id: props.row.id } }"
          class="flex items-center mr-3"
        >
          <EyeIcon class="w-4 h-4 mr-1" />
          {{ $t('table.read') }}
        </router-link>
        <a
          v-if="checkPermission('tickets.tickets.delete')"
          href="javascript:"
          @click="$emit('delete', props.row.id)"
          class="flex items-center text-theme-6"
        >
          <Trash2Icon class="w-4 h-4 mr-1" />
          {{ $t('table.delete') }}
        </a>
      </p>
    </template>
  </TableRegular>
</template>

<script>
import TableRegular from '@/components/ui/table/TableRegular';
import tableMixin from '@/mixins/tableMixin';
import PeopleFullname from '@/components/people/PeopleFullname';
import InputSelect from '@/components/ui/input/InputSelect';
import { PRIORITY_LEVELS, CHANNELS } from '@/utils/constants';

export default {
  mixins: [tableMixin],

  props: { users: Array },

  data() {
    return {
      priorityLevels: PRIORITY_LEVELS,
      channels: CHANNELS,
      selectedUser: '',
      assignedActions: [
        { id: 'CONFIRMED', name: this.$t('statusTicketAction.confirmed') },
        { id: 'PENDING', name: this.$t('statusTicketAction.returned') },
      ],
      confirmedActions: [
        { id: 'FINISHED', name: this.$t('statusTicketAction.finished') },
        { id: 'PENDING', name: this.$t('statusTicketAction.returned') },
      ],
      finishedActions: [
        { id: 'APPROVED', name: this.$t('statusTicketAction.approved') },
        { id: 'PENDING', name: this.$t('statusTicketAction.returned') },
        { id: 'DECLINED', name: this.$t('statusTicketAction.decline') },
      ],
    };
  },

  methods: {
    getPriorityName(value) {
      const priority = this.priorityLevels.find(item => item.id === parseInt(value, 10));
      return priority ? priority.name : '';
    },
    getChannelName(value) {
      const channel = this.channels.find(item => item.id === value);
      return channel ? channel.name : '';
    },
    onAssign(userId, status, ticketId) {
      this.$emit('onChangeStatus', { status, ticketId, userId: parseInt(userId, 10) });
    },
    onChangeStatus(assignedUser, status, ticketId) {
      if (assignedUser) {
        this.$emit('onChangeStatus', {
          status,
          ticketId,
          userId: status === 'PENDING' ? null : parseInt(assignedUser.id, 10),
        });
      } else {
        this.$emit('onChangeStatus', {
          status: 'PENDING',
          ticketId,
          userId: null,
        });
      }
    },
    getOptions(status) {
      if (status === 'ASSIGNED') {
        return this.assignedActions;
      }
      if (status === 'CONFIRMED') {
        return this.confirmedActions;
      }
      return this.finishedActions;
    },
  },

  components: { TableRegular, PeopleFullname, InputSelect },
};
</script>
