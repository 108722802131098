<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <PageHeader
        class="text-lg font-medium mr-auto"
        :title="`${title}: ${forum && forum.comments ? forum.comments.length : 0}`"
      />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <Button
          type="button"
          :to="{ name: 'Forums' }"
          class="box text-gray-700 dark:text-gray-300 flex"
        >
          {{ $t('text.back') }}
        </Button>
      </div>
    </div>
    <div class="intro-y mt-5">
      <div class="intro-y box p-6 space-y-6">
        <div v-for="(comment, index) in forum.comments" :key="index">
          <div class="mb-2 flex justify-between">
            <PeopleFullname :item="comment.user" class="mr-4" />
            <span class=" opacity-60">
              {{ comment.createdAt | momentDate('YYYY-MM-DD HH:mm') }}
            </span>
          </div>
          <p>{{ comment.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FORUMS_SHOW } from '@/store/actions/forums';
import { validationMixin } from 'vuelidate';
import Button from '@/components/ui/button/Button';
import PageHeader from '@/components/ui/PageHeader';
import PeopleFullname from '@/components/people/PeopleFullname';

export default {
  mixins: [validationMixin],

  components: {
    Button,
    PageHeader,
    PeopleFullname,
  },

  props: {
    business: Object,
  },

  computed: {
    ...mapGetters(['me', 'forumsStatus', 'forum']),
    isLoading() {
      return this.forumsStatus === 'loading';
    },
    title() {
      return this.$t(`nav.forumComments`);
    },
  },

  async created() {
    await this.fetchData();
  },

  methods: {
    fetchData() {
      if (this.$route.params.id) {
        const payload = { id: this.$route.params.id };
        this.$store.dispatch(FORUMS_SHOW, payload);
      }
    },
  },
};
</script>
