import Vue from 'vue';
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';
import Toasted from 'vue-toasted';
import en from 'vee-validate/dist/locale/en';
import * as rules from 'vee-validate/dist/rules';
import VueCookies from 'vue-cookies';
import VueSlider from 'vue-slider-component';
import VueGoodTablePlugin from 'vue-good-table';
import VueHtmlToPaper from 'vue-html-to-paper';
import VTooltip from 'v-tooltip';
import VueApexCharts from 'vue-apexcharts';
import _ from 'lodash';
import App from './App';
import router from './router';
import store from './store';
import initializeFilters from './filters';
import './global-components';
import './libs';
import i18n from './i18n';
import './assets/sass/app.scss';

Vue.prototype._ = _;

const vueHtmlToPaperOptions = {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
  styles: ['https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css'],
};
Vue.use(VueHtmlToPaper, vueHtmlToPaperOptions);

// install rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
localize('en', en);
// Install components globally
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.use(VTooltip);
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);
Vue.use(Toasted, {
  theme: 'toasted-custom',
  position: 'bottom-right',
  duration: 5000,
  containerClass: 'notification-bottom-right',
});
Vue.use(VueCookies);
Vue.component('VueSlider', VueSlider);
Vue.use(VueGoodTablePlugin);

Vue.config.productionTip = false;

initializeFilters(Vue);
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
